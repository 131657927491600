import React from 'react'
import { v4 } from 'uuid'
import { Button, Box, HStack, Tag, TagLeftIcon, TagLabel } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GiCutDiamond, GiEternalLove, GiAmericanShield } from 'react-icons/gi'
import { useTheme } from '@emotion/react'
import { format } from 'date-fns'

import LandingPage, {
  Header,
  Gallery,
  Features,
  Faqs,
  Video,
  Testimonies,
} from '../components/landingPage'

import {
  H1,
  P,
  Image,
  WhatsappButton,
  Article,
  Hero,
  HeroBody,
  HeroTitle,
  HeroSubtitle,
  H3,
  ColVideo,
  ColInfo,
  RowContent,
  Section,
  FeatureList,
  FeatureItem,
  ColImage,
} from '../ui'
import FloatingBook from '../components/floatingBook'

import Layout from '../components/layout'
import { ColTitle, SectionTitle, Title } from '../components/texts'
import ButtonBook from '../components/buttonBook'

import youtubePreview from '../images/landing/jets/gallery/6.jpg'
import DividerVertical from '../components/dividerVertical'

function PrivateJetsPage({ data }) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Layout disableWhatsapp title={t('landing:jets.title')}>
      <Hero
        justifyContent="center"
        minHeight={{ base: '50vh', md: '60vh' }}
        gallery={[
          <Image
            fluid={data.hero.childImageSharp.fluid}
            layout="background"
            style={{ opacity: 0.85 }}
          />,
        ]}
      >
        <HeroBody justifyContent="center" alignItems="center" marginTop="auto" marginBottom="30px">
          <HeroSubtitle>SECURITY · COMFORT · LUXURY</HeroSubtitle>
        </HeroBody>
      </Hero>

      <Article>
        <Section titleAlign="center" justifyContent="center">
          <DividerVertical />
          <Title color="secondary.800">{t('landing:jets.hero.title')}</Title>
          <P textAlign="center">{t('landing:jets.hero.desc')}</P>
          <DividerVertical />
        </Section>

        <Section spacing={10}>
          <RowContent>
            <ColImage
              borderBottom={`4px solid ${theme.colors.secondary[800]}`}
              image={<Image fixed={data.why1.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo backgroundColor="whiteAlpha.200">
              <ColTitle>{t('landing:jets.why.features.one.title')}</ColTitle>
              <P>{t('landing:jets.why.features.one.desc')}</P>
              <ButtonBook
                as="a"
                href={`https://api.whatsapp.com/send?phone=5219983497125&text=${t(
                  'landing:jets.whatsappText'
                )}`}
                size="lg"
                width="fit-content"
              >
                {t('landing:jets.button')}
              </ButtonBook>
            </ColInfo>
          </RowContent>

          <RowContent reversedInMobile>
            <ColInfo backgroundColor="whiteAlpha.200">
              <ColTitle>{t('landing:jets.why.features.two.title')}</ColTitle>
              <P>{t('landing:jets.why.features.two.desc')}</P>
              <ButtonBook
                as="a"
                href={`https://api.whatsapp.com/send?phone=5219983497125&text=${t(
                  'landing:jets.whatsappText'
                )}`}
                size="lg"
                width="fit-content"
              >
                {t('landing:jets.button')}
              </ButtonBook>
            </ColInfo>
            <ColImage
              borderBottom={`4px solid ${theme.colors.secondary[800]}`}
              image={<Image fixed={data.why2.childImageSharp.fixed} layout="background" />}
            />
          </RowContent>

          <RowContent>
            <ColImage
              borderBottom={`4px solid ${theme.colors.secondary[800]}`}
              image={<Image fixed={data.why3.childImageSharp.fixed} layout="background" />}
            />
            <ColInfo backgroundColor="whiteAlpha.200">
              <ColTitle>{t('landing:jets.why.features.three.title')}</ColTitle>
              <P>{t('landing:jets.why.features.three.desc')}</P>
              <ButtonBook
                as="a"
                href={`https://api.whatsapp.com/send?phone=5219983497125&text=${t(
                  'landing:jets.whatsappText'
                )}`}
                size="lg"
                width="fit-content"
              >
                {t('landing:jets.button')}
              </ButtonBook>
            </ColInfo>
          </RowContent>
        </Section>

        <Section spacing={10}>
          <SectionTitle>{t('landing:testimonies.title')}</SectionTitle>
          <Testimonies
            as="div"
            paddingLeft="0"
            paddingRight="0"
            paddingTop="0"
            paddingBottom="0"
            list={t('landing:testimonies.items', { returnObjects: true })}
            stars={5}
          />
        </Section>

        <Section spacing={10}>
          <SectionTitle>{t('landing:video.title')}</SectionTitle>
          <Video
            light={youtubePreview}
            as="div"
            paddingLeft="0"
            paddingRight="0"
            paddingTop="0"
            paddingBottom="0"
            videoUrl="https://www.youtube.com/watch?v=J9yU6ySp9qw"
          />
        </Section>

        <Section spacing={10}>
          <SectionTitle>Gallery</SectionTitle>
          <Gallery
            as="div"
            paddingLeft="0"
            paddingRight="0"
            paddingTop="0"
            paddingBottom="0"
            viewPhotos={t('landing:gallery.viewPhotos')}
            images={data.gallery.edges.map((image) => (
              <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
          />
        </Section>

        <Section spacing={10}>
          <SectionTitle>Frequently Asked Questions</SectionTitle>
          <Faqs
            as="div"
            paddingLeft="0"
            paddingRight="0"
            paddingTop="0"
            paddingBottom="0"
            list={t('landing:jets.faqs.items', { returnObjects: true })}
          />
        </Section>
      </Article>

      <FloatingBook
        title={t('landing:jets.floatingBookNow.title')}
        desc={t('landing:jets.floatingBookNow.desc')}
        button={
          <ButtonBook flexShrink="0" size="md">
            {t('landing:jets.button')}
          </ButtonBook>
        }
      />

      <WhatsappButton
        bottom="75px"
        href={`https://api.whatsapp.com/send?phone=5219983497125&text=${t(
          'landing:jets.whatsappText'
        )}`}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "private-jets/hero.jpg" }) {
      ...bannerSectionImage
    }

    gallery: allFile(
      filter: { relativeDirectory: { eq: "landing/jets/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    why1: file(relativePath: { eq: "landing/jets/experience.jpg" }) {
      ...fixedImage
    }

    why2: file(relativePath: { eq: "landing/jets/concierge.jpg" }) {
      ...fixedImage
    }

    why3: file(relativePath: { eq: "landing/jets/exclusive.jpg" }) {
      ...fixedImage
    }
  }
`

export default PrivateJetsPage
